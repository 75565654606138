<template>
  <div class="box">
    <!-- 选择任务按钮 -->
    <div style="display: flex; align-items: center">
      <div class="condition-item">
        <el-button type="primary" @click="funSelectSpare" size="small"
          >+选择散拍任务</el-button
        >
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-select
          size="small"
          style="width: 200px"
          v-model="photographer"
          placeholder="请选择摄影师"
          :loading="loading"
          multiple
        >
          <el-option
            v-for="item in photographers"
            :key="item.photographerName"
            :label="item.photographerName"
            :value="item.photographerName"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 校拍任务列表盒子 -->
    <div
      style="display: flex; flex-direction: column; height: calc(100vh - 390px)"
    >
      <el-table
        border
        :data="SpareList"
        style="width: 100%; text-align: center"
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @selection-change="handleSelectionMerge"
        ref="selectionAll"
      >
        <el-table-column align="center" fixed type="selection" width="50">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" width="240">
        </el-table-column>

        <el-table-column
          align="center"
          prop="studentName"
          label="姓名"
          width="80"
        >
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.studentName, "name")
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactTell"
          label="手机号"
          width="120"
        >
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.contactTell, "ph")
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="idNumber"
          label="身份证号"
          width="220"
        >
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.idNumber, "IdCard")
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="schoolName"
          label="学校"
          width="180"
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="studentCode"
          label="学号"
          width="160"
        >
        </el-table-column>

        <el-table-column align="center" prop="major" label="专业" width="160">
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="采集时间"
          width="180"
        >
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          label="支付类型"
          width="180"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.payType == 0">线下支付</span>
            <span v-show="scope.row.payType == 1">工行支付</span>
            <span v-show="scope.row.payType == 2">微信支付</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="major" label="状态" width="160">
        </el-table-column>
        <el-table-column align="center" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              class="d-btn"
              type="danger"
              @click.native.prevent="handleDelete(scope.$index, SpareList)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px">
      已选择<span style="color: #446cf3; padding: 0px 10px">{{ mergeNum }}</span
      >条订单
    </div>
    <div style="text-align: center">
      <el-button
        type="primary"
        size="small"
        @click="merge"
        :loading="loading"
        :disabled="loading"
        v-if="mergeButtom"
        >创建任务</el-button
      >
    </div>
    <!-- 新增弹窗 -->
    <div>
      <el-dialog
        title="选择订单"
        :visible.sync="dialogVisible"
        width="80%"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">订单号</label>
            <el-input
              clearable
              v-model="searchTitle"
              placeholder="请输入订单号"
              autocomplete="off"
              size="small"
              style="width: 180px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">采集时间</label>
            <el-date-picker
              v-model="searchCreateDate"
              type="date"
              placeholder="选择开始时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
            <label class="fn-14" style="padding-left: 10px">-</label>
            <el-date-picker
              v-model="searchCreateDateEnd"
              type="date"
              placeholder="选择结束时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
          </div>
          <div class="condition-item">
            <label class="fn-14">姓名</label>
            <el-input
              clearable
              v-model="searchStudentName"
              placeholder="请输入学生名称"
              autocomplete="off"
              size="small"
              style="width: 120px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">手机号</label>
            <el-input
              clearable
              v-model="searchSell"
              placeholder="请输入手机号"
              autocomplete="off"
              size="small"
              style="width: 160px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              clearable
              v-model="searchIdNumber"
              placeholder="请输入身份证号"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="handleSearch"
              >搜索</el-button
            >
          </div>
        </div>
        <!-- 弹窗列表盒子 -->
        <div>
          <el-table
            border
            :data="choiceList"
            style="width: 100%; text-align: center"
            height="400"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            @selection-change="handleSelectiSchool"
            v-loading="tableSpareLoading"
          >
            <template #empty>
              <p>{{ tableSpareLoading == true ? "数据加载中" : "暂无数据" }}</p>
            </template>
            <el-table-column fixed align="center" type="selection" width="50">
            </el-table-column>

            <el-table-column prop="orderNo" label="订单号" width="240">
            </el-table-column>

            <el-table-column
              align="center"
              prop="studentName"
              label="姓名"
              width="80"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.studentName, "name")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="contactTell"
              label="手机号"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.contactTell, "ph")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="idNumber"
              label="身份证号"
              width="220"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.idNumber, "IdCard")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              width="180"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="studentCode"
              label="学号"
              width="160"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="major"
              label="专业"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="采集时间"
              width="180"
            >
              <template slot-scope="scope">
                {{ formDateFormat(scope.row.createTime) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="payType"
              label="支付类型"
              width="180"
            >
              <template slot-scope="scope">
                <span v-show="scope.row.payType == 0">线下支付</span>
                <span v-show="scope.row.payType == 1">工行支付</span>
                <span v-show="scope.row.payType == 2">微信支付</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              width="180"
            >
              <template slot-scope="scope">
                <span v-show="scope.row.status == 0">下单</span>
                <span v-show="scope.row.status == 1">预付款</span>
                <span v-show="scope.row.status == 2">完成</span>
                <span v-show="scope.row.status == 3">取消</span>
                <span v-show="scope.row.status == 5">取消中</span>
                <span v-show="scope.row.status == 6">预取消</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div style="display: flex; justify-content: right; padding-top: 10px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[20, 50, 100, 200, 300]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handlePreservation"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { casualPhotoTaskAdd, queryOrders, ordersCount } from "@/api/order";
import { dateFormat } from "@/utils/date";
import { queryParam } from "../../../api/param.js";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "NewOrderSpMgt",
  data() {
    return {
      dialogVisible: false,
      tableSpareLoading: false,
      tableSelection: [],
      tableSelectionMerge: [],
      searchTitle: "",
      searchStudentName: "",
      searchSell: "",
      searchIdNumber: "",
      searchCreateDate: "",
      searchCreateDateEnd: "",
      choiceList: [],
      SpareList: [],
      photographer: null,
      photographers: [],

      currentPage: 1,
      pageSize: 100,
      total: 0,
      mergeNum: 0,
      loading: false,
      currentIndex: -1,
      postDataTime: {},

      //权限按钮
      mergeButtom: false,
    };
  },
  mounted() {
    this.queryParam();
    this.queryButton();
  },

  methods: {
    queryButton() {
      const data = 130;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "mergeButtom") {
            this.mergeButtom = true;
          }
        }
      });
    },
    selectionAll() {
      this.$nextTick(() => {
        this.$refs.selectionAll.toggleAllSelection();
      });
    },
    queryParam() {
      queryParam().then((resp) => {
        let arrList = [];
        arrList = resp.data.photographer;
        let arr = arrList.split(",");
        let res = [];
        arr.forEach((item) => {
          res.push({ photographerName: item });
        });
        this.photographers = res;
      });
    },
    funSelectSpare() {
      this.dialogVisible = true;
      (this.searchTitle = ""),
        (this.searchStudentName = ""),
        (this.searchSell = ""),
        (this.searchIdNumber = ""),
        (this.searchCreateDate = ""),
        (this.searchCreateDateEnd = ""),
        this.querySpare();
    },
    querySpare() {
      // 弹窗搜索条件
      const postData = {};
      postData.orderNo = this.searchTitle;
      postData.studentName = this.searchStudentName;
      postData.sell = this.searchSell;
      postData.idNumber = this.searchIdNumber;

      if (this.searchCreateDate !== "") {
        postData.beginTime = this.formDateTimeBegin(this.searchCreateDate);
      }
      if (this.searchCreateDateEnd !== "") {
        postData.endTime = this.formDateTimeEnd(this.searchCreateDateEnd);
      }
      // 弹窗列表数据
      this.tableSpareLoading = true;
      queryOrders(postData, this.currentPage, this.pageSize).then((resp) => {
        // console.log(resp);
        if (resp.code == 0) {
          let datas = resp.data.content;
          this.choiceList = datas;
          this.total = resp.data.totalElements;
        }
        this.tableSpareLoading = false;
      });
    },
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    //搜索
    handleSearch() {
      this.currentPage = 1;
      //日期回退一周
      let data = { beginTime: "", endTime: "" };
      let timeInterval = Number(3600 * 1000 * 24 * 7);
      if (this.searchCreateDate || this.searchCreateDateEnd) {
        if (this.searchCreateDate != "") {
          data.beginTime = this.formDateTimeBegin(
            new Date(this.searchCreateDate).getTime() - timeInterval
          );
        }
        if (this.searchCreateDateEnd != "") {
          data.endTime = this.formDateTimeEnd(
            new Date(this.searchCreateDateEnd).getTime() - timeInterval
          );
        }
        ordersCount(data).then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: res.data,
              type: "success",
            });
          }else{
            return res.error
          }
        });
      }

      this.querySpare();
    },
    // 创建合并选择
    handleSelectionMerge(val) {
      this.tableSelectionMerge = val;
      this.mergeNum = this.tableSelectionMerge.length;
    },
    // 创建合并
    merge() {
      // 弹窗搜索条件
      let json = JSON.parse(JSON.stringify(this.tableSelectionMerge));
      let postData = {};
      let ids = [];
      json.map((item) => {
        ids.push(item.orderNo);
      });
      if (!ids.length) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
      } else {
        postData.ids = ids.join(",");

        postData.photographer = this.photographer.join(",");
        postData.minDate = this.postDataTime.beginTime || "";
        postData.maxDate = this.postDataTime.endTime || "";
        if (postData.photographer == null) {
          this.$notify({
            title: "警告",
            message: "请选择摄影师",
            type: "warning",
          });
        } else {
          this.loading = true;
          casualPhotoTaskAdd(postData)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "温馨提示",
                  message: resp.message,
                  type: "success",
                });
                this.loading = false;
                (this.photographer = null),
                  (this.tableSelectionMerge = this.SpareList = []);
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      }
    },
    // 弹窗选择
    handleSelectiSchool(val) {
      this.tableSelection = val;
    },
    // 弹窗选择确定
    handlePreservation() {
      const tableSelection = JSON.parse(JSON.stringify(this.tableSelection));
      if (tableSelection === null || tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
        return;
      }

      let Sparedata = [];
      if (this.SpareList.length == 0) {
        Sparedata = tableSelection;
        for (const item of Sparedata) {
          this.SpareList.push(item);
        }
      } else {
        for (const item of tableSelection) {
          const json = this.SpareList.filter((o) => o.orderNo == item.orderNo);
          if (!json.length) {
            this.SpareList.push(item);
          }
        }
      }
      if (this.searchCreateDate !== "") {
        this.postDataTime.beginTime = this.formDateTimeBegin(
          this.searchCreateDate
        );
      }
      if (this.searchCreateDateEnd !== "") {
        this.postDataTime.endTime = this.formDateTimeEnd(
          this.searchCreateDateEnd
        );
      }
      this.dialogVisible = false;
      this.selectionAll();
    },

    // 移除
    handleDelete(index, rows) {
      rows.splice(index, 1);
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.querySpare();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySpare();
    },
    formDateTimeBegin(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 00:00:00", new Date(dateValue));
      }
    },
    formDateTimeEnd(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 23:59:59", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if ((mod = "IdCard")) {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style scoped>
.el-checkbox__inner {
  border-radius: 20px !important;
}
/* 概览标题 */
.box div.overview {
  display: flex;
  align-items: center;
  width: 100px;
  height: 30px;
  font-size: 14px;
}
.box div.overview img {
  width: 20%;
}
/* 选择任务按钮 */
.box .btn {
  margin: 15px 0px 10px 8px;
  line-height: 10px;
  height: 35px;
  width: 160px;
}
/* 移除按钮 */
.d-btn {
  width: 85px;
  height: 30px;
  text-align: center;
  line-height: 5px;
}
/* 列表盒子 */
.box div.plan-box {
  margin: 10px;
}
</style>
